import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
  Inject,
} from '@angular/core';
import { UhLeadsService } from '../uhleads.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SharedService } from 'src/app/shared/shared.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { LayoutService } from 'src/app/layout/layout.service';
import { TitleCasePipe, formatDate } from '@angular/common';
import { MarketingService } from '../marketing/marketing.service';
import * as xlsx from 'xlsx';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Exceldata2 } from '../marketing/smsmarketing/models/ExecelData.model2';
import { start } from 'repl';
import { AuthService } from 'src/app/account/auth/auth.service';
import { Router } from '@angular/router';
import { forEach, now, round } from 'lodash';
import { CreateAgent } from '../create-agent/create-agent.service';
import {
  countUpTimerConfigModel,
  CountupTimerService,
  timerTexts,
} from 'ngx-timer';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MomentFormatService } from 'src/app/moment-format-service';
import { NotificationsService } from 'src/app/notification.service';
import * as _ from 'lodash';

import { fromEvent } from 'rxjs';
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
} from 'rxjs/operators';
import { RoleService } from '../role-management/role.service';
import { ErrorHandlerService } from 'src/app/error-handler.service';
var sortJsonArray = require('node_modules/sort-json-array');
const countryCodes = require('../../../assets/allcountryDetails.json');

@Component({
  selector: 'app-sfc-leads',
  templateUrl: './sfc-leads.component.html',
  styleUrls: ['./sfc-leads.component.css'],
})
export class SfcLeadsComponent implements OnInit {
  public fileSource = environment.fileServer;
  public userData: any;
  public filterValue = 'all';
  nav_position: string = 'end';
  public statusValue = 'pending';
  public page: any = 1;
  public pageSize: any = 12;
  public search: string;
  totalApplications: number;
  completedApplications: number;
  completedApplications2: number;
  incompleteapplications: number;
  documentspending: number;
  documentssubmitted: number;
  callback: number;
  activityData: any;
  activityData2: any;

  activityTable: any = [];
  activityTable2: any;

  startDate: any;
  endDate: any;
  leadsbody: any;
  companyId: any;
  companyAdmin: Boolean = false;
  rotating: boolean = false;
  projectId: any;
  downloadLink: any;
  public filePath: any;
  public fileData: Exceldata2[] = [];
  public uploaded: boolean = false;
  public error: boolean = false;
  public insertCount: number = 0;
  public leadType: string = 'all';
  public errorMessage: string = '';
  public submitted: boolean = false;
  public edited: boolean = false;
  admin: boolean = false;
  public counselling: boolean = false;
  isCallcomming: boolean = false;
  connection: any = null;
  // audio: any;
  audio = new Audio();
  audioStatus: boolean = false;
  sideViewerData: any = { Activities: [], CallDetails: [] };
  documentsList: any;
  documentsList2: any;
  selectedCallerId: any;
  selectedLead: any;
  commentText: string = '';
  newStatus: any;
  newStatusCheck = true;
  docUrl: any;
  currentLeadId: any;
  currentGuid: any;
  documentComments: string;
  validationStatus: boolean = false;
  currentItem: any;
  showdeferredintake: Boolean = false;
  DIyearArray: any = [];
  @ViewChild('uploadFile') uploadFile;
  @ViewChild('closeButton') closeButton;
  @ViewChild('closeButton2') closeButton2;
  @ViewChild('closeButton3', { static: true }) closeButton3;
  @ViewChild('sideNav', { static: true }) sideNav;
  @ViewChild('inputString', { static: true }) input: ElementRef;
  @ViewChild('totalRecords', { static: true }) totalRecords: ElementRef;

  leadIds = [];
  selectedAll: boolean = false;
  agentList: any;
  GBPagentList: any;
  defaultAgent = 'default';
  selectedAgent: any;

  testConfig: any;
  // callStarted: boolean = false;
  startCall: boolean = false;
  public stopCall: boolean = false;
  public clearTime: any;
  dialerScreen1 = 'dialer';
  phoneNumber: any = '';
  agentCall: String = '';
  isShownMarketing: boolean = false;
  dailerScreen: any = 'nocall';
  isCallingProgress: boolean = false;
  isOutgoing: boolean = false;
  isIncoming: boolean = false;
  disabled: any;
  callStatus: string = 'Calling..';
  tab: any;
  showDate: boolean = false;

  nextCallerName: any;
  nextCallerCity: any;
  nextCallerNumber: any;

  public marketingId: {
    id: string;
    status: string;
    phoneNumber: string;
    email: string;
    name: string;
    module: string;
    city: string;
    nextCaller: any;
    lead: any;
  } = {
      id: '',
      status: '',
      name: '',
      email: '',
      phoneNumber: '',
      module: '',
      city: '',
      nextCaller: null,
      lead: {},
    };

  totalAgents: any;
  agentName: any;

  public callData: any[] = [];
  public startIndex: number = 0;

  date1: any;
  date2: any;
  serializedDate = new FormControl(new Date().toISOString());
  documentsDenied: number;
  CallsPending: number;
  revalidate: any;
  minItems = 5;
  unassigned: any;
  assigned: any;
  private colors = [
    [{ bg: 'blueviolet', color: 'white' }],
    [{ bg: 'AntiqueWhite', color: 'white' }],
    [{ bg: 'Coral', color: 'white' }],
    [{ bg: 'Crimson', color: 'white' }],
    [{ bg: 'DarkOrange', color: 'white' }],
    [{ bg: 'DeepPink', color: 'white' }],
    [{ bg: '#c5924e', color: 'white' }],
  ];
  assignedtome: any;
  file: File[] = [];
  customDocName: string;
  docUploadError: string;
  docUploadError2: string = 'Select files to upload.';
  customFileList: File[] = [];
  customDocLeadId: any;
  leadtoEdit: {
    LeadId: string;
    FirstName: string;
    LastName: string;
    CountryCode: string;
    PhoneNumber: string;
    Email: string;
    DOB: Date;
    EnrollmentYear: string;
    EnrollmentTerm: string;
    Address: string;
    City: string;
    State: string;
    Zip: string;
    Country: string;
    LeadComment: string;
    CallStatus: string;
    AgentMail: string;
    DIyear?: string;
    DIterms?: string;
    studentapplicationtype?: string;
  };
  editComment: string;
  editStatus: string;
  allRecords: any;
  isLoading: boolean = true;
  noRecords: boolean = false;
  selectedFiles: File[] = [];
  docUploadID: any;
  docUploadStatus: boolean = false;
  activityfilterString: string = '';
  uploadSuccess: string = 'none';
  topBarCounts = {
    validatedDocs: '0',
    completedDocs: '0',
    studentDenied: '0',
    reopen: '0',
    total: '0',
    revalidatedDocs: '0',
    pendingDocs: '0',
    documentsSubmitted: '0',
    unassigned: '0',
    assigned: '0',
    assignedtoMe: '0',
    uncontactable: '0',
    graduate: '0',
    graduate_transfer: '0',
    freshman: '0',
    transfer: '0',
    graduate_visiting_student: '0',
    non_degree: '0',
  };
  isTopBarBind: Boolean = false;
  cardLeadType: string = 'all';
  thresItem: any;
  thresId: any;
  thresModal: NgbModalRef;
  pageNumber: Number = 1;
  totalPages: Number = 0;
  currentCard: string = 'total';
  sortColumn = 'updatedAt';
  sortOrder = 'des';
  smsList: [] = [];
  smsListWp: [] = [];
  projectLists: any;
  smsText: string;

  DIyear: any;
  DIterms: any;
  callSid: any;
  countries: any[] = [];
  selectedCountryCode = 1;
  selectedCountryName = 'United States of America';
  docName: any;
  allMajors: any = [];
  majors: any = [];
  terms: any[] = [];
  listMajors: any;
  listTerms: any[] = [];
  selectedProgram: any;
  selectedTerm: any;

  constructor(
    public uhleadsservice: UhLeadsService,
    public sharedService: SharedService,
    public dashBoardService: DashboardService,
    public layoutService: LayoutService,
    public marketingService: MarketingService,
    private readonly loginService: AuthService,
    private readonly modalService: NgbModal,
    private authService: AuthService,
    private router: Router,
    private agentService: CreateAgent,
    public countupTimerService: CountupTimerService,
    private momentService: MomentFormatService,
    private NotificationService: NotificationsService,
    private companyService: RoleService,
    private handleError: ErrorHandlerService,
    @Inject(DOCUMENT) private domService: HTMLDocument
  ) {
    this.testConfig = new countUpTimerConfigModel();
    this.testConfig.timerClass = 'test_Timer_class';
    this.testConfig.timerTexts = new timerTexts();
    this.testConfig.timerTexts.hourText = ':'; //default - hh
    this.testConfig.timerTexts.minuteText = ':'; //default - mm
    this.testConfig.timerTexts.secondsText = ' ';
  }

  ngOnInit(): void {
    this.getInitValues();
    var x = document.getElementsByClassName(
      'sideDataViewer'
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
    }
    this.getAgentNames();
    this.fetchCountries();
    this.sharedService.pageEvent.emit({
      pageName: 'Leads Dashboard',
    });
    this.userData = JSON.parse(localStorage.getItem('userData'));
    //
    this.date1 = new FormControl(new Date());
    this.date2 = new FormControl(new Date(Date.now() - 864e5));
    this.filterValue == '24hrs';
    var today = new Date();
    this.endDate = new Date(Date.now());
    this.startDate = new Date(Date.now() - 864e5 * 365);

    this.startDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() - 864e5 * 365)).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() + 864e5))
    );

    this.companyService.projectAdmin(this.companyId).subscribe(
      (projects) => {
        this.projectId = projects.filter(
          (x) => x.Name == environment.Sfcleads
        )[0].id;
        if (this.projectId != null) {
          this.sharedService.getProjectAdmin(this.projectId).subscribe(
            (data) => {
              this.admin = data;
              this.getTopBarCRM();
              this.getAllData(
                this.userData,
                'all',
                new Date(Date.now() - 864e5 * 365),
                new Date(Date.now())
              );
              this.showTotalApplications(); //Shohw only pending records on page  load
              this.onClick(1); //Activating pending card by default

              this.uhleadsservice
                .getAgentList(this.projectId, this.userData.id)
                .subscribe(
                  (data: any) => {
                    this.agentList = data;
                  },
                  (error) => {
                    this.handleError.handleError(error);
                  }
                );

              ////

              this.getDownloadLink();
              this.uhleadsservice.startTimersfc.subscribe((res) => {
                this.callStatus = 'inProgress';
                this.startTimer();
              });

              this.uhleadsservice.viewReceivedLeadsfc.subscribe((res: any) => {
                if (res) {
                  this.viewLead(res);
                }
              });

              this.uhleadsservice.pageNumber.subscribe((pageNo: any) => {
                this.pageNumber = pageNo;
                if (this.leadType != 'filter') {
                  // this.getTopBarCRM();
                  this.getCardCount(
                    {
                      admin: this.admin,
                      mail: this.userData.mail,
                      ProjectId: this.projectId,
                      leadType: this.cardLeadType,
                      start: this.startDate,
                      end: this.endDate,
                    },
                    this.cardLeadType
                  );
                } else if (this.leadType == 'filter') {
                  // this.searchLeads(this.search, this.pageNumber)

                  let payload = {
                    admin: this.admin,
                    ProjectId: this.projectId,
                    start: '2021-08-02 09:40:25+00',
                    end: '2022-08-03 09:40:25+00',
                    page: this.pageNumber,
                    leadType: 'All',
                    mail: 'All',
                    callStatus: 'All',
                    course: 'All',
                    enrollmentYear: 'All',
                    enrollmentTerm: 'All',
                    export: false,
                    search: this.search,
                  };
                  if (this.search.length > 0) {
                    this.uhleadsservice
                      .GetSearchDashboardLeads(payload)
                      .subscribe(
                        (res: any) => {
                          this.activityTable = res.data;
                        },
                        (error) => {
                          this.handleError.handleError(error);
                        }
                      );
                  } else {
                    // this.totalRecords.nativeElement.click();
                    this.leadType = 'all';
                  }
                }
              });
            },
            (error) => {
              this.handleError.handleError(error);
            }
          );
        } else {
          this.admin = false;
        }
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );

    // server-side search
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(1500),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.search = this.input.nativeElement.value;
          this.pageNumber = 1;
          //
          //
          this.searchLeads(this.input.nativeElement.value, this.pageNumber);
        })
      )
      .subscribe();

    //incoming sms check
    this.uhleadsservice.incomingSmssfc.subscribe((from: any) => {
      if (from.from) {
        this.uhleadsservice
          .getSms(this.currentLeadId)
          .subscribe((smsList: any) => { });
      }
    });
    let d = new Date();
    this.DIyearArray.push(d.getFullYear());
    let year = d.getFullYear();
    for (let i = 0; i < 4; i++) {
      year = year + 1;
      this.DIyearArray.push(year);
    }
    this.uhleadsservice.callStatussfc.subscribe((status) => {
      this.callStatus = status;
    });
    this.uhleadsservice.getCallSidsfc.subscribe((sid) => {
      this.callSid = sid;
    });

    const studentAppTypeControl = this.uhleadsservice.createLead;
    studentAppTypeControl.get('studentapplicationtype').setValidators([Validators.required]);
    studentAppTypeControl.get('studentapplicationtype').updateValueAndValidity();
  }
  getInitValues() {
    this.fetchCountries();
    this.uhleadsservice.getAllMajors().subscribe((data: any) => {
      this.majors = data;
      this.allMajors = this.majors;
    });
    this.uhleadsservice.getAllTerms().subscribe((data: any) => {
      // this.terms = data;
      // data.map((item, index) => {
      //   this.terms.push({
      //     id: index + 1,
      //     year: moment().year(),
      //     term: item.name,
      //     value: item.name + moment().year(),
      //     name: _.capitalize(item.name) + " " + moment().year()
      //   })
      // })
      // Define the dates for removing Fall terms
      const fall1Date = moment('2024-11-07');
      const fall2Date = moment('2024-11-14');
      // Check the current date
      const currentDate = moment();
      const currentMonth = moment().month(); // get the current month (0-11)
      const currentYear = moment().year(); // get the current year
      const monthsRemaining = 12 - currentMonth; // calculate the months remaining in the current year

      if (monthsRemaining <= 4) {
        // if there are 3 months or less remaining, add the next year
        const nextYear = currentYear + 1;
        data.map((item, index) => {
          if (
            !item.name.includes('Summer') &&
            !item.name.includes('Spring') &&
            item.name != 'Fall'
          ) {
            this.terms.push({
              id: index + 1,
              year: currentYear,
              term: item.name,
              value: item.name + currentYear,
              name: _.capitalize(item.name) + ' ' + currentYear,
            });
          }
        });
        data.map((item, index) => {
          this.terms.push({
            id: index + 1 + data.length,
            year: nextYear,
            term: item.name,
            value: item.name + nextYear,
            name: _.capitalize(item.name) + ' ' + nextYear,
          });
        });
      } else {
        // otherwise, only add the current year
        data.map((item, index) => {
          this.terms.push({
            id: index + 1,
            year: currentYear,
            term: item.name,
            value: item.name + currentYear,
            name: _.capitalize(item.name) + ' ' + currentYear,
          });
        });
      }
      // Remove `Fall-1 2024` if today's date is November 7, 2024
      if (currentDate.isSameOrAfter(fall1Date, 'day')) {
        this.terms = this.terms.filter((term) => term.name !== 'Fall-1 2024');
      }

      // Remove `Fall-2 2024` if today's date is November 14, 2024
      if (currentDate.isSameOrAfter(fall2Date, 'day')) {
        this.terms = this.terms.filter((term) => term.name !== 'Fall-2 2024');
      }
    });
  }
  startTimer() {
    this.stopTimer();
    let cdate = new Date();
    cdate.setHours(cdate.getHours());
    this.countupTimerService.startTimer(cdate);
  }
  stopTimer() {
    this.countupTimerService.stopTimer();
  }

  onClick(check) {
    this.uhleadsservice.pageNumber.next(1);
    if (check == 1) {
      this.tab = 'tab1';
    } else if (check == 2) {
      this.tab = 'tab2';
    } else if (check == 3) {
      this.tab = 'tab3';
    } else if (check == 4) {
      this.tab = 'tab4';
    } else if (check == 5) {
      this.tab = 'tab5';
    } else if (check == 6) {
      this.tab = 'tab6';
    } else if (check == 7) {
      this.tab = 'tab7';
    } else if (check == 8) {
      this.tab = 'tab8';
    } else if (check == 9) {
      this.tab = 'tab9';
    } else if (check == 10) {
      this.tab = 'tab10';
    } else if (check == 11) {
      this.tab = 'tab11';
    } else if (check == 12) {
      this.tab = 'tab12';
    } else if (check == 13) {
      this.tab = 'tab13';
    } else if (check == 14) {
      this.tab = 'tab14';
    } else if (check == 15) {
      this.tab = 'tab15';
    } else if (check == 16) {
      this.tab = 'tab16';
    } else if (check == 17) {
      this.tab = 'tab17';
    } else if (check == 18) {
      this.tab = 'tab18';
    } else if (check == 19) {
      this.tab = 'tab19';
    } else {
      this.tab = null;
    }
  }

  selectAllforAssignment() {
    if (this.selectedAll == false) {
      this.selectedAll = true;
      this.leadIds = [];
      for (let i = 0; i < this.activityTable.length; i++) {
        this.leadIds.push(this.activityTable[i].id);
      }
    } else if (this.selectedAll == true) {
      this.selectedAll = false;
      this.leadIds = [];
    }
  }

  selectAgentforAssignment(e, item) {
    if (e.target.checked) {
      this.leadIds.push(item.id);
    } else if (!e.target.checked) {
      this.removeItem(this.leadIds, item.id);
    }
  }

  assignAgentModal() {
    this.defaultAgent = 'default';
    this.selectedAgent = null;
  }
  agentSelected(selectedAgent) {
    this.selectedAgent = selectedAgent;
  }
  assignLEads() {
    if (this.leadIds.length == 0) {
      this.uhleadsservice.showWarning('Select leads to be assigned', 'Alert!');
      return;
    }
    if (this.selectedAgent) {
      this.uhleadsservice
        .assignLeads({
          AssignedTo: this.selectedAgent,
          InsertedBy: this.userData.mail,
          LeadIds: this.leadIds,
        })
        .subscribe(
          (res) => {
            let payload = {
              AgentMail: this.userData.mail,
              GBPAssignedTo: this.selectedAgent,
              GUID: '',
              LeadId: this.leadIds,
              Platform: 'CRM',
              StudentDocId: 0,
              Title: 'Assigned',
              description:
                'New leads are assigned to you by ' +
                this.getAgentName(this.userData.mail),
              notificationType: 'AssignedToAgent',
              token: this.userData.token,
            };
            this.NotificationService.sendNotifications(payload).subscribe(
              (res: any) => {
                if (res.status === 200) {
                }
              },
              (error) => {
                this.handleError.handleError(error);
              }
            );
            this.uhleadsservice.showSuccess(
              'Successfully assigned the agents',
              'Success'
            );
            // this.getDashboardLeads();
            // this.getData(this.userData, "all", this.startDate, this.endDate);
            this.getTopBarCRM();
            this.getCardData(
              this.userData,
              'total',
              this.startDate,
              this.endDate
            );
            this.selectedAll = false;
            this.closeButton.nativeElement.click();
            document.getElementById('closeAssignButton').click();
          },
          (error) => {
            this.handleError.handleError(error);
          }
        );
    } else {
      this.uhleadsservice.showWarning('Please select an agent', 'Alert!');
    }
  }

  removeItem(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }
  setLeadType() {
    this.leadType = '';
  }

  showTotalApplications() {
    this.statusValue = 'all';
    this.leadType = 'all';
    this.currentCard = 'total';
    // this.getCounts(this.userData, "all", this.startDate, this.endDate);
    // this.getCounts(this.userData, "all", this.startDate, this.endDate, "all");
    this.getTopBarCRM();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: 'all',
        start: this.startDate,
        end: this.endDate,
      },
      'all'
    );
  }
  //Shows date if the filter value is custom
  hideDate() {
    // this.filterValuev
    if (this.filterValue == 'custom' && this.showDate == false) {
      this.showDate = true;
    }
  }

  showCompletedApplications() {
    this.statusValue = 'all';
    this.leadType = 'validatedDocs';
    this.currentCard = 'validated';

    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "validatedDocuments"
    // );
    this.getTopBarCRM();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: 'validatedDocs',
        start: this.startDate,
        end: this.endDate,
      },
      'validatedDocs'
    );
  }
  showUncontactable() {
    this.statusValue = 'all';
    this.leadType = 'uncontactable';
    this.currentCard = 'uncontactable';

    this.getTopBarCRM();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: 'uncontactable',
        start: this.startDate,
        end: this.endDate,
      },
      'uncontactable'
    );
  }
  showCompletedApplications2() {
    this.statusValue = 'all';
    this.leadType = 'completedDocs';
    this.currentCard = 'completed';
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "completedDocuments"
    // );
    this.getTopBarCRM();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: 'completedDocs',
        start: this.startDate,
        end: this.endDate,
      },
      'completedDocs'
    );
  }

  showIncompleteApplications() {
    this.statusValue = 'all';
    this.leadType = 'pendingDocs';
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "ValidationPending"
    // );
    this.getTopBarCRM();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: 'pendingDocs',
        start: this.startDate,
        end: this.endDate,
      },
      'pendingDocs'
    );
  }
  showDocumentsPending() {
    this.statusValue = 'all';
    this.leadType = 'pendingDocs';
    this.currentCard = 'updated';
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "documentsPending"
    // );
    this.getTopBarCRM();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: 'pendingDocs',
        start: this.startDate,
        end: this.endDate,
      },
      'pendingDocs'
    );
  }
  showDocumentsSubmitted() {
    this.statusValue = 'all';
    this.leadType = 'documentsSubmitted';
    this.currentCard = 'updated';
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "documentsSubmitted"
    // );
    this.getTopBarCRM();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: 'documentsSubmitted',
        start: this.startDate,
        end: this.endDate,
      },
      'documentsSubmitted'
    );
  }
  showCallback() {
    this.statusValue = 'all';
    this.leadType = 'callback';
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "callback"
    // );
    this.getTopBarCRM();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: 'callback',
        start: this.startDate,
        end: this.endDate,
      },
      'callback'
    );
  }
  showCallsPending() {
    this.statusValue = 'all';
    this.leadType = 'pending';
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "pendingCalls"
    // );
    this.getTopBarCRM();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: 'pending',
        start: this.startDate,
        end: this.endDate,
      },
      'pending'
    );
  }

  showRevalidate() {
    this.statusValue = 'all';
    this.leadType = 'revalidatedDocs';
    this.currentCard = 'revalidate';
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "revalidate"
    // );
    this.getTopBarCRM();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: 'revalidatedDocs',
        start: this.startDate,
        end: this.endDate,
      },
      'revalidatedDocs'
    );
  }

  showApplicationsDenied() {
    this.statusValue = 'all';
    this.leadType = 'studentDenied';
    this.currentCard = 'denied';
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "denied"
    // );
    this.getTopBarCRM();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: 'studentDenied',
        start: this.startDate,
        end: this.endDate,
      },
      'studentDenied'
    );
  }

  showUnassigned() {
    this.statusValue = 'all';
    this.leadType = 'unassigned';
    this.currentCard = 'total';
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "unassigned"
    // );
    this.getTopBarCRM();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: 'unassigned',
        start: this.startDate,
        end: this.endDate,
      },
      'unassigned'
    );
  }

  showAssigned() {
    this.statusValue = 'all';
    this.leadType = 'assigned';
    this.currentCard = 'assigned';
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "assigned"
    // );
    this.getTopBarCRM();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: 'assigned',
        start: this.startDate,
        end: this.endDate,
      },
      'assigned'
    );
  }

  showAssignedtoMe() {
    this.statusValue = 'all';
    this.leadType = 'assignedtoMe';
    this.currentCard = 'assigned';
    // this.getCounts(
    //   this.userData,
    //   "all",
    //   this.startDate,
    //   this.endDate,
    //   "assignedtome"
    // );
    var obj = {
      admin: this.admin,
      mail: this.userData.mail,
      ProjectId: this.projectId,
      leadType: 'assignedtoMe',
      start: this.startDate,
      end: this.endDate,
    };
    this.getTopBarCRM();
    this.getCardCount(obj, 'assignedtoMe');
  }
  showCardDetails(lead?: any) {
    this.statusValue = 'all';
    this.leadType = lead;
    this.currentCard = lead;
    var obj = {
      admin: this.admin,
      mail: this.userData.mail,
      ProjectId: this.projectId,
      leadType: lead,
      start: this.startDate,
      end: this.endDate,
    };
    this.getTopBarCRM();
    this.getCardCount(obj, lead);
  }

  public onInput(event: any) {
    let payload = {
      admin: this.admin,
      ProjectId: this.projectId,
      start: '2021-08-02 09:40:25+00',
      end: '2022-08-03 09:40:25+00',
      page: 1,
      leadType: 'All',
      mail: 'All',
      callStatus: 'All',
      course: 'All',
      enrollmentYear: 'All',
      enrollmentTerm: 'All',
      export: false,
      search: this.search,
    };
    if (this.search != '') {
      // this.uhleadsservice
      //   .GetSearchDashboardLeads(payload)
      //   .subscribe((res: any) => {
      //     //
      //     this.activityTable = res.data;
      //   });
      // this.activityTable = this.allRecords;
    } else if (this.search == '') {
      // this.getData(this.userData, "all", this.startDate, this.endDate);
      this.totalRecords.nativeElement.click();
      // this.getTopBarCRM();
      // this.getCardData(this.userData, "total", this.startDate, this.endDate);
    }
  }

  searchLeads(value, page) {
    this.leadType = 'all';
    this.currentCard = 'total';
    this.activityTable = [];
    this.isLoading = true;
    this.noRecords = false;
    let payload = {
      admin: this.admin,
      ProjectId: this.projectId,
      start: '2020-08-02 09:40:25+00',
      end: '2025-08-03 09:40:25+00',
      page: page,
      leadType: 'All',
      mail: 'All',
      callStatus: 'All',
      course: 'All',
      enrollmentYear: 'All',
      enrollmentTerm: 'All',
      export: false,
      search: value,
    };

    if (value.length > 0) {
      this.leadType = 'filter';
      this.uhleadsservice.GetSearchDashboardLeads(payload).subscribe(
        (res: any) => {
          this.isLoading = false;
          this.activityTable = res.data;

          for (let i = 0; i < this.activityTable.length; i++) {
            if (this.activityTable[i].PhoneNumber == '0000000000') {
              this.activityTable[i].PhoneNumber = '-';
              this.activityTable[i].CountryCode = '-';
            }
            this.activityTable[i].AssignedtoName = this.getAgentName(
              this.activityTable[i].Assignedto
            );
            this.activityTable[i].AssignedtoInitial = this.getAgentInitial(
              this.activityTable[i].Assignedto
            );
            this.activityTable[i].AssignedtoColor = this.getAgentColor(
              this.activityTable[i].Assignedto
            );
            this.activityTable[i].AssignedtoImageURL = this.getAgentphoto(
              this.activityTable[i].Assignedto
            );
            if (
              this.activityTable[i].LeadDocStatus == 'CRM-Revalidate' ||
              this.activityTable[i].LeadDocStatus == 'Admin-Revalidate' ||
              this.activityTable[i].LeadDocStatus == 'Admin-revalidate' ||
              this.activityTable[i].LeadDocStatus == 'CRM-revalidate'
            ) {
              this.activityTable[i].LeadDocStatus = 'Revalidate';
            }
          }
          if (this.activityTable.length <= 0) {
            this.noRecords = true;
          }
          let newTotal = ~~(Number(res.totalResults) / 12) + 1;
          this.uhleadsservice.totalPages.next(Number(newTotal));
        },
        (error) => {
          this.isLoading = false;
          this.noRecords = true;
          this.handleError.handleError(error);
        }
      );
    } else {
      this.leadType = 'all';
      this.getTopBarCRM();
      this.getCardData(this.userData, 'total', this.startDate, this.endDate);
    }
  }

  //Table Filter
  dropdownSelect(event) {
    this.uhleadsservice.pageNumber.next(1);
    this.onClick(null);
    this.leadType = 'dropdown';
    this.cardLeadType = event;
    this.getTopBarCRM();
    // this.getCardData(this.userData, event, this.startDate, this.endDate);
  }

  updateDateFilter(event: any) {
    if (event.target.value != 'custom') {
      this.endDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now())).getTime()
      );
    }
    if (event.target.value == 'custom') {
      //show custom date filter
      this.showDate = true;
    } else if (event.target.value == 'all') {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 365)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == '24hrs') {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == '72hrs') {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == '7days') {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == '14days') {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == '30days') {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      );

      this.showDate = false;
    }
    // this.getCounts(this.userData, "all", this.startDate, this.endDate, "all");
    this.getTopBarCRM();
    this.getCardCount(
      {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: this.cardLeadType,
        start: this.startDate,
        end: this.endDate,
      },
      this.cardLeadType
    );

    // this.getData(this.userData, "all", this.startDate, this.endDate);
    this.getTopBarCRM();
    this.getCardData(this.userData, 'total', this.startDate, this.endDate);
  }

  updateActivity() {
    // this.getData(this.userData, "all", this.startDate, this.endDate);
    this.getTopBarCRM();
    this.getCardData(this.userData, 'total', this.startDate, this.endDate);
    this.totalApplications = this.activityTable.length;
    this.completedApplications = this.activityTable.filter(function (el) {
      return el.LeadDocStatus == 'validated' || el.LeadDocStatus == 'Validated';
    }).length;
    this.completedApplications2 = this.activityTable.filter(function (el) {
      return el.LeadDocStatus == 'completed' || el.LeadDocStatus == 'complete';
    }).length;
    this.incompleteapplications = this.activityTable.filter(function (el) {
      return el.APPStatus == 'Incomplete';
    }).length;
    this.documentspending = this.activityTable.filter(function (el) {
      return el.Phase == 'pending';
    }).length;
    this.documentssubmitted = this.activityTable.filter(function (el) {
      return el.Phase == 'complete' || el.APPStatus == 'complete';
    }).length;
    this.callback = this.activityTable.filter(function (el) {
      return el.Phase == 'callback' || el.Phase == 'Callback';
    }).length;
  }

  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.startDate).getTime
    );
  }
  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.endDate).getTime
    );
  }

  getDownloadLink() {
    this.marketingService
      .getExcelLink(
        this.fileSource + '/TemplateDocuments/' + environment.Sfconucsvfile
      )
      .subscribe(
        (data) => {
          this.downloadLink = data['url'];
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
  }

  redirectToUpload() {
    this.router.navigate(['./upload/' + this.projectId]);
  }

  convertFile(e) {
    this.filePath = e.target.files[0]['name'].toString();
    let reader = new FileReader();

    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = (eve) => {
      let data = new Uint8Array(reader.result as any);
      let wb = xlsx.read(data, { type: 'array' });
      let sheet_name = wb.SheetNames;
      let sheet_data: any[][] = xlsx.utils.sheet_to_json(
        wb.Sheets[sheet_name[0]],
        { header: 1 }
      );
      if (sheet_data[0][0] === 'GUID' && sheet_data.length !== 0) {
        this.fileData = this.uhleadsservice.convert2dArraytoObject(sheet_data);

        this.uhleadsservice
          .postCallData({
            data: this.fileData,
            createdBy: this.userData['mail'],
            ProjectId: this.projectId,
            addType: 'Excel',
          })
          .subscribe(
            (data) => {
              this.uploaded = true;
              this.error = false;
              this.insertCount = data['count'];
              setTimeout(() => {
                this.uploaded = false;
                this.filePath = '';
              }, 5000);
              // this.getDashboardLeads(this.leadType);
              // this.getData(this.userData, "all", this.startDate, this.endDate);
              this.getTopBarCRM();
              this.getCardData(
                this.userData,
                'total',
                this.startDate,
                this.endDate
              );
            },
            (error) => {
              this.handleError.handleError(error);
            }
          );
      } else {
        this.error = true;
        this.errorMessage = 'Please Upload a Valid File';
      }
    };
    this.uploadFile.nativeElement.value = '';
  }

  get createLeads() {
    return this.uhleadsservice.createLead.controls;
  }
  get editLeads() {
    return this.uhleadsservice.editLead.controls;
  }
  transformToTitleCase(value: string): string {
    const titleCasePipe = new TitleCasePipe();
    return titleCasePipe.transform(value);
  }
  createLead() {
    this.uhleadsservice.createLead.controls['form_name'].setValue('none');
    this.uhleadsservice.createLead.controls['channel'].setValue('none');
    this.submitted = true;
    if (this.uhleadsservice.createLead.invalid) {
      return;
    }
    let fileData: Exceldata2[] = [
      new Exceldata2(
        this.uhleadsservice.createLeadValue['GUID'],
        this.uhleadsservice.createLeadValue['firstName'],
        this.uhleadsservice.createLeadValue['lastName'],
        this.uhleadsservice.createLeadValue['countryCode'],
        this.uhleadsservice.createLeadValue['phoneNumber'],
        this.uhleadsservice.createLeadValue['email'],
        '',
        this.uhleadsservice.createLeadValue['program'],
        'Y',
        this.uhleadsservice.createLeadValue['CreatedDate'],
        this.uhleadsservice.createLeadValue['comments'],
        this.transformToTitleCase(
          this.uhleadsservice.createLeadValue['studentapplicationtype']
        ),
        this.uhleadsservice.createLeadValue['EnrollmentTerm'],
        this.uhleadsservice.createLeadValue['EnrollmentYear'],
        true
      ),
    ];
    this.uhleadsservice
      .postCallData({
        data: fileData,
        createdBy: this.userData['mail'],
        ProjectId: this.projectId,
        addType: 'Manual',
      })
      .subscribe(
        (data) => {
          this.uhleadsservice.showSuccess('Lead Added', 'Success');
          this.selectedProgram = '';
          this.selectedTerm = '';
          this.uhleadsservice.resetCreateLead();
          this.submitted = false;
          this.modalService.dismissAll();
          this.leadType = 'all';
          this.getTopBarCRM();
          this.getCardData(
            this.userData,
            'total',
            this.startDate,
            this.endDate
          );
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
  }

  getDashboardLeads(leadType) {
    if (!this.projectId) {
      return;
    }
    // this.statusValue = "pending";
    this.leadType = leadType;
    return this.marketingService
      .getDashboardLeads({
        admin: this.admin,
        // admin: true,
        mail: this.userData['mail'],
        ProjectId: this.projectId,
        leadType: this.leadType,
        counselling: this.counselling,
      })
      .subscribe(
        (data) => {
          this.callData = data;
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
  }

  //Date Filter
  filterData() {
    this.onClick(null);
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.date2.value).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.date1.value).getTime()
    );

    if (this.endDate < this.startDate) {
      // window.alert("Choose the right date format!");
      this.uhleadsservice.showWarning(
        'End date cannot be less than Start date!',
        'Wrong Date!'
      );
      return;
    } else {
      // this.getData(this.userData, "all", this.startDate, this.endDate);
      this.getTopBarCRM();
      this.getCardData(this.userData, 'total', this.startDate, this.endDate);
      // this.getCounts(this.userData, "all", this.startDate, this.endDate, "all");
      this.getTopBarCRM();
      this.getCardCount(
        {
          admin: this.admin,
          mail: this.userData.mail,
          ProjectId: this.projectId,
          leadType: this.cardLeadType,
          start: this.startDate,
          end: this.endDate,
        },
        this.cardLeadType
      );
      this.showDate = false;
    }
  }
  getCounts(userData: any, leadtype, startDate, endDate, filter) {
    this.leadIds = [];
    this.activityTable = [];
    this.isLoading = true;
    this.noRecords = false;
    this.search = '';
    // this.activityTable = [];
    this.companyService.projectAdmin(this.companyId).subscribe(
      (projects) => {
        this.projectId = projects.filter(
          (x) => x.Name == environment.Sfcleads
        )[0].id;
        this.leadsbody = {
          admin: this.admin,
          // admin: true,
          mail: userData.mail,
          ProjectId: this.projectId,
          leadType: leadtype,
          start: this.startDate,
          end: this.endDate,
        };

        this.uhleadsservice.getActivityTable2(this.leadsbody).subscribe(
          (res) => {
            this.activityData2 = res;

            // this.callData = this.activityTable;
            this.activityData2.forEach(function (a) {
              Object.keys(a).map((key) => {
                a[key] === null ? (a[key] = '-') : a[key];
              });
            });

            for (let i = 0; i < this.activityData2.length; i++) {
              if (this.activityData2[i].PhoneNumber == '0000000000') {
                this.activityData2[i].PhoneNumber = '-';
                this.activityData2[i].CountryCode = '-';
              }

              this.activityData2[i].AssignedtoName = this.getAgentName(
                this.activityData2[i].Assignedto
              );
              this.activityData2[i].AssignedtoInitial = this.getAgentInitial(
                this.activityData2[i].Assignedto
              );
              this.activityData2[i].AssignedtoColor = this.getAgentColor(
                this.activityData2[i].Assignedto
              );
              this.activityData2[i].AssignedtoImageURL = this.getAgentphoto(
                this.activityData2[i].Assignedto
              );
              if (
                this.activityData2[i].LeadDocStatus == 'CRM-Revalidate' ||
                this.activityData2[i].LeadDocStatus == 'Admin-Revalidate' ||
                this.activityData2[i].LeadDocStatus == 'Admin-revalidate' ||
                this.activityData2[i].LeadDocStatus == 'CRM-revalidate'
              ) {
                this.activityData2[i].LeadDocStatus = 'Revalidate';
              }
            }

            this.activityData = this.activityData2;
            this.totalApplications = this.activityData.length;
            this.completedApplications = this.activityData.filter(function (
              el
            ) {
              return el.LeadDocStatus == 'validated';
            }).length;
            this.completedApplications2 = this.activityData.filter(function (
              el
            ) {
              return (
                el.LeadDocStatus == 'completed' ||
                el.LeadDocStatus == 'Completed'
              );
            }).length;
            this.incompleteapplications = this.activityData.filter(function (
              el
            ) {
              // return el.LeadDocStatus != "validated";
              return (
                el.LeadDocStatus == 'pending' ||
                el.LeadDocStatus == 'Admin-revalidate' ||
                el.LeadDocStatus == 'revalidate' ||
                el.LeadDocStatus == 'CRM-revalidate' ||
                el.LeadDocStatus == 'Revalidate' ||
                el.LeadDocStatus == 'reopen' ||
                el.LeadDocStatus == 'denied'
              );
            }).length;
            var count = 0;

            this.documentspending = this.activityData.filter(function (el) {
              if (el.uploadlistofdocuments.length == 0) {
                count = count + 1;
              } else {
                var checkDock = true;
                for (let i = 0; i < el.uploadlistofdocuments.length; i++) {
                  if (el.uploadlistofdocuments[i].StudentDocPath == null) {
                    checkDock = false;
                  }
                }
                if (checkDock == false) {
                  count = count + 1;
                } else {
                }
              }
              return el.Phase == 'pending';
            }).length;
            this.documentspending = count;
            this.documentsDenied = this.activityData.filter(function (el) {
              return (
                el.LeadDocStatus == 'denied' || el.LeadDocStatus == 'Denied'
              );
            }).length;
            this.CallsPending = this.activityData.filter(function (el) {
              return el.Phase == 'pending' || el.Phase == 'Pending';
            }).length;
            this.revalidate = this.activityData.filter(function (el) {
              return (
                el.LeadDocStatus == 'revalidate' ||
                el.LeadDocStatus == 'Revalidate' ||
                el.LeadDocStatus == 'CRM-revalidate' ||
                el.LeadDocStatus == 'GBP-revalidate'
              );
            }).length;
            this.unassigned = this.activityData.filter(function (el) {
              return el.Assignedto == '' || el.Assignedto == '-';
            }).length;

            this.assigned = this.activityData.length - this.unassigned;

            this.assignedtome = this.activityData.filter(function (el) {
              return el.Assignedto == userData.mail;
            }).length;
            this.unassigned = this.activityData.filter(function (el) {
              return el.Assignedto == '' || el.Assignedto == '-';
            }).length;

            this.assigned = this.activityData.length - this.unassigned;

            this.documentssubmitted =
              this.totalApplications - this.documentspending;

            this.callback = this.activityData.filter(function (el) {
              return el.Phase == 'callback' || el.Phase == 'Callback';
            }).length;

            var DocPendingList = [];
            var DocSubmittedList = [];

            if (filter == 'all') {
              this.activityTable = this.activityData;
            } else if (filter == 'validatedDocuments') {
              this.activityTable = this.activityData.filter(function (el) {
                return el.LeadDocStatus == 'validated';
              });
            } else if (filter == 'completedDocuments') {
              this.activityTable = this.activityData.filter(function (el) {
                return el.LeadDocStatus == 'completed';
              });
            } else if (filter == 'ValidationPending') {
              this.activityTable = this.activityData.filter(function (el) {
                return (
                  el.LeadDocStatus == 'pending' ||
                  el.LeadDocStatus == 'Admin-revalidate' ||
                  el.LeadDocStatus == 'revalidate' ||
                  el.LeadDocStatus == 'CRM-revalidate' ||
                  el.LeadDocStatus == 'Revalidate' ||
                  el.LeadDocStatus == 'reopen' ||
                  el.LeadDocStatus == 'denied'
                );
              });
            } else if (filter == 'completedApplications') {
              this.activityTable = this.activityData.filter(function (el) {
                return el.APPStatus == 'complete' || el.APPStatus == 'complete';
              });
            } else if (filter == 'incompleteApplications') {
              this.activityTable = this.activityData.filter(function (el) {
                return el.APPStatus == 'Incomplete';
              });
            } else if (filter == 'documentsPending') {
              this.activityTable = this.activityData.filter(function (el) {
                if (el.uploadlistofdocuments.length == 0) {
                  DocPendingList.push(el);
                } else {
                  var checkDock = true;
                  for (let i = 0; i < el.uploadlistofdocuments.length; i++) {
                    if (el.uploadlistofdocuments[i].StudentDocPath == null) {
                      checkDock = false;
                    }
                  }
                  if (checkDock == false) {
                    DocPendingList.push(el);
                  }
                }
                return el.Phase == 'pending';
              });
              this.activityTable = DocPendingList;
            } else if (filter == 'documentsSubmitted') {
              this.activityTable = this.activityData.filter(function (el) {
                if (el.uploadlistofdocuments.length != 0) {
                  var docCheck = true;
                  for (let i = 0; i < el.uploadlistofdocuments.length; i++) {
                    if (el.uploadlistofdocuments[i].StudentDocPath == null) {
                      docCheck = false;
                    }
                  }
                  if (docCheck == true) {
                    DocSubmittedList.push(el);
                  }
                }
                return el.Phase == 'complete' || el.APPStatus == 'complete';
              });
              this.activityTable = DocSubmittedList;
            } else if (filter == 'callback') {
              this.activityTable = this.activityData.filter(function (el) {
                return el.Phase == 'callback' || el.Phase == 'Callback';
              });
            } else if (filter == 'denied') {
              this.activityTable = this.activityData.filter(function (el) {
                return (
                  el.LeadDocStatus == 'Denied' || el.LeadDocStatus == 'denied'
                );
              });
            } else if (filter == 'pendingCalls') {
              this.activityTable = this.activityData.filter(function (el) {
                return el.Phase == 'pending' || el.Phase == 'Pending';
              });
            } else if (filter == 'revalidate') {
              this.activityTable = this.activityData.filter(function (el) {
                return (
                  el.LeadDocStatus == 'revalidate' ||
                  el.LeadDocStatus == 'Revalidate' ||
                  el.LeadDocStatus == 'CRM-revalidate' ||
                  el.LeadDocStatus == 'GBP-revalidate'
                );
              });
            } else if (filter == 'assigned') {
              this.activityTable = this.activityData.filter(function (el) {
                return el.Assignedto != '-';
              });
            } else if (filter == 'assignedtome') {
              this.activityTable = this.activityData.filter(function (el) {
                return el.Assignedto == userData.mail;
              });
            } else if (filter == 'unassigned') {
              this.activityTable = this.activityData.filter(function (el) {
                return el.Assignedto == '-' || el.Assignedto == '';
              });
            } else if (filter == 'assigned') {
              this.activityTable = this.activityData.filter(function (el) {
                return el.Assignedto != '-';
              });
            } else if (filter == 'unassigned') {
              this.activityTable = this.activityData.filter(function (el) {
                return el.Assignedto == '-' || el.Assignedto == '';
              });
            }

            this.isLoading = false;
            if (this.activityTable.length == 0) {
              this.noRecords = true;
            } else {
              this.noRecords = false;
            }
          },
          (error) => {
            this.handleError.handleError(error);
          }
        );
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
    this.getAllData(
      this.userData,
      'all',
      new Date(Date.now() - 864e5 * 365),
      new Date(Date.now())
    );
  }

  getData(userData: any, leadtype, startDate, endDate) {
    this.leadIds = [];
    this.isLoading = true;
    this.noRecords = false;
    this.search = '';
    this.onClick(null);
    this.activityTable = [];

    this.leadsbody = {
      admin: this.admin,
      // admin: true,
      mail: userData.mail,
      ProjectId: this.projectId,
      leadType: leadtype,
      start: this.startDate,
      end: this.endDate,
    };

    this.uhleadsservice.getActivityTable2(this.leadsbody).subscribe(
      (res) => {
        this.activityTable2 = res;

        this.activityTable2.forEach(function (a) {
          Object.keys(a).map((key) => {
            a[key] === null ? (a[key] = '-') : a[key];
          });
        });

        for (let i = 0; i < this.activityTable2.length; i++) {
          if (this.activityTable2[i].PhoneNumber == '0000000000') {
            this.activityTable2[i].CountryCode = '-';
            this.activityTable2[i].PhoneNumber = '-';
          }
          this.activityTable2[i].AssignedtoName = this.getAgentName(
            this.activityTable2[i].Assignedto
          );

          this.activityTable2[i].AssignedtoInitial = this.getAgentInitial(
            this.activityTable2[i].Assignedto
          );
          this.activityTable2[i].AssignedtoColor = this.getAgentColor(
            this.activityTable2[i].Assignedto
          );
          this.activityTable2[i].AssignedtoImageURL = this.getAgentphoto(
            this.activityTable2[i].Assignedto
          );
          if (
            this.activityTable2[i].LeadDocStatus == 'CRM-Revalidate' ||
            this.activityTable2[i].LeadDocStatus == 'Admin-Revalidate' ||
            this.activityTable2[i].LeadDocStatus == 'Admin-revalidate' ||
            this.activityTable2[i].LeadDocStatus == 'CRM-revalidate'
          ) {
            this.activityTable2[i].LeadDocStatus = 'Revalidate';
          }
        }

        this.activityTable = this.activityTable2;
        this.getAllData(
          this.userData,
          'all',
          new Date(Date.now() - 864e5 * 365),
          new Date(Date.now())
        );
        // this.getAgentName()
        this.callData = this.activityTable;
        this.isLoading = false;
        if (this.activityTable.length == 0) {
          this.noRecords = true;
        } else {
          this.noRecords = false;
        }
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  getAllData(userData: any, leadtype, startDate, endDate) { }

  getCallData() { }

  startCallListener() {
    this.uhleadsservice.startCallsfc.next({
      phoneNumber: this.marketingId?.phoneNumber,
      leadData: this.marketingId,
    });
  }

  startCallListener2(data, index) {
    this.showdeferredintake = false;
    this.DIyear = null;
    this.DIterms = null;

    this.dialerScreen1 = 'outgoing1';
    this.callStatus = 'Calling..';
    $('input[name=option1]').prop('checked', false);

    this.newStatusCheck = true;

    this.callData[0] = data;
    this.selectedLead = data;

    this.uhleadsservice.getDocumentDetails(data.GUID).subscribe(
      (res) => {
        this.documentsList = res;
        this.documentsList = this.documentsList.uploadDocsStatus;
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
    this.marketingId = {
      id: data?.id,
      status: data?.CallStatus,
      name: data?.FirstName,
      email: data?.Email,
      phoneNumber: '+' + data?.CountryCode + data?.PhoneNumber,
      // phoneNumber: "+919848311098",
      module: data?.Module,
      city: data?.City,
      nextCaller: this.activityTable.length > 1 ? 1 : 0,
      lead: 0,
    };

    if (this.activityTable.length > 1) {
      var nextFirstname = this.activityTable[index + 1]?.FirstName
        ? this.activityTable[index + 1]?.FirstName
        : '';
      var nextLastname = this.activityTable[index + 1]?.LastName
        ? this.activityTable[index + 1]?.LastName
        : '';
      var nextCallerCity = this.activityTable[index + 1]?.City
        ? this.activityTable[index + 1]?.City
        : '';
      var nextCallerCountryCode = this.activityTable[index + 1]?.CountryCode
        ? this.activityTable[index + 1]?.CountryCode.replace('+', '')
        : '';
      var nextCallerPhoneNumber = this.activityTable[index + 1]?.PhoneNumber
        ? this.activityTable[index + 1]?.PhoneNumber
        : '';

      this.nextCallerName = nextFirstname;
      this.nextCallerCity = nextCallerCity;
      this.nextCallerNumber = nextCallerCountryCode
        ? '+' + nextCallerCountryCode + nextCallerPhoneNumber
        : '';
      this.startIndex = index + 1;
    }
    this.uhleadsservice.startCallsfc.next({
      phoneNumber: this.marketingId?.phoneNumber,
      leadData: this.marketingId,
    });
    this.startCall = true;
  }

  //Start Call timer

  // conferenceCall(from) {}

  conferenceCall(value: any) {
    this.uhleadsservice.conferenceCallsfc.next({ value });
  }

  conferenceCall2(value: any) {
    if (value.phone_number) {
      this.marketingService
        .conferenceCallConnect(
          {
            CallSid: this.connection.parameters.CallSid,
            phone: value.phone_number,
            callerId: this.selectedCallerId,
          },
          environment.companyId
        )
        .subscribe(
          () => { },
          (error) => {
            this.handleError.handleError(error);
          }
        );
    }
    if (value.manager[0]) {
      if (value.manager[0].Name) {
        this.marketingService
          .conferenceCallConnect(
            {
              CallSid: this.connection.parameters.CallSid,
              phone: 'client:' + value.manager[0].value,
              callerId: this.selectedCallerId,
            },
            environment.companyId
          )
          .subscribe(
            () => { },
            (error) => {
              this.handleError.handleError(error);
            }
          );
      }
    }
  }

  updateComments() {
    if (this.newStatusCheck) {
    } else {
      // this.uhleadsservice.getCallSidsfc.subscribe(
      //   (data) => {
      this.uhleadsservice
        .updateComments2({
          id: this.selectedLead.id,
          comments: this.commentText,
          callStatus: this.newStatus,
          diyear: this.DIyear,
          diterms: this.DIterms,
          CallSid: this.callSid,
        })
        .subscribe(
          (res) => {
            this.showdeferredintake = false;
            this.DIyear = null;
            this.DIterms = null;
            // this.getData(this.userData, "all", this.startDate, this.endDate);
            this.getTopBarCRM();
            this.getCardData(
              this.userData,
              'total',
              this.startDate,
              this.endDate
            );
          },
          (error) => {
            this.handleError.handleError(error);
          }
        );
      this.commentText = '';
      this.closeButton2.nativeElement.click();
    }
  }

  resetCallingScreen() {
    this.commentText = '';
    this.newStatus = null;
    this.newStatusCheck = false;
    this.showdeferredintake = false;
    this.DIyear = null;
    this.DIterms = null;
  }
  onStatusChange(data) {
    this.newStatus = data;
    this.newStatusCheck = false;
    if (this.newStatus == 'deferredintake') {
      this.showdeferredintake = true;
    } else {
      this.showdeferredintake = false;
      this.DIyear = null;
      this.DIterms = null;
    }
  }

  sendSms(item, HTMLid) {
    this.smsText = (<HTMLInputElement>document.getElementById(HTMLid)).value;
    let fromNumber = '';
    this.companyService
      .projectAdmin(environment.companyId)
      .subscribe((res: any) => {
        this.projectLists = res;

        for (let i = 0; i < res.length; i++) {
          if (res[i].Name == environment.Sfcleads) {
            fromNumber = res[i].TwilioNumber;
          }
        }
        let payload = {
          text: this.smsText,
          from: fromNumber,
          to: '+' + item.CountryCode + item.PhoneNumber,
          AgentMail: this.userData.mail,
        };

        this.uhleadsservice
          .sendSms(payload, this.projectId)
          .subscribe((res: any) => {
            this.uhleadsservice.showInfo('Message successfully sent.', 'Sent');
            this.uhleadsservice
              .getSms(this.currentLeadId)
              .subscribe((smsList: any) => {
                if (smsList.SMSDetails) {
                  this.smsList = smsList.SMSDetails;
                  (<HTMLInputElement>document.getElementById(HTMLid)).value =
                    '';
                }
              });
          });
      });
  }

  sideNavData(data) {
    this.activityfilterString = '';
    this.file = null;
    this.customDocName = '';
    this.minItems = 5;
    this.sideViewerData = [];
    var x = document.getElementsByClassName(
      'sideDataViewer'
    ) as HTMLCollectionOf<HTMLElement>;
    //  x.style.display = none;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = '';
    }
    this.uhleadsservice.getStudentActivity(data.id).subscribe(
      (res: any) => {
        this.uhleadsservice.getSms(data.id).subscribe((smsList: any) => {
          if (smsList?.SMSDetails) {
            this.smsList = smsList?.SMSDetails;
          }
        });
        let wpObj = {
          phone: '+' + res?.CountryCode.replace('+', '') + res?.PhoneNumber,
        };
        this.uhleadsservice.getSmsWp(wpObj).subscribe((wpsmsList: any) => {
          if (wpsmsList) {
            this.smsListWp = wpsmsList?.data;
          }
        });
        if (res.APPStatus == 'complete') {
          res.APPStatus = 'Completed';
        }
        this.sideViewerData = res;

        this.currentLeadId = this.sideViewerData.id;
        if (this.customDocLeadId != this.currentLeadId) {
          this.customFileList = [];
          this.customDocLeadId = this.currentLeadId;
        } else {
          this.customDocLeadId = this.currentLeadId;
        }
        this.currentGuid = this.sideViewerData.GUID;
        this.replaceNull(this.sideViewerData);
        this.documentValidation(data.id);
        function replaceNull(a: any) {
          Object.keys(a).map((key) => {
            a[key] === null ? (a[key] = '-') : a[key];
          });
        }
        this.sideViewerData.uploadlistofdocuments.forEach((x) => {
          x.DocComments.forEach((y) => {
            y.AgentColor = this.getAgentColor(y.AgentMail);
          });
        });
        for (
          let i = 0;
          i < this.sideViewerData.uploadlistofdocuments.length;
          i++
        ) {
          this.sideViewerData.LeadComments[i].AgentColor = this.getAgentColor(
            this.sideViewerData.LeadComments[i].AgentMail
          );
        }
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  replaceNull(a: any) {
    Object.keys(a).map((key) => {
      a[key] === null ? (a[key] = '-') : a[key];
    });
  }

  documentValidation(id) {
    this.uhleadsservice.getDocumentComments(id).subscribe(
      (res) => {
        this.documentsList2 = res;
        this.documentsList2 = this.documentsList2.uploadlistofdocuments;

        this.documentsList2.forEach(function (a) {
          Object.keys(a).map((key) => {
            a[key] === null ? (a[key] = '-') : a[key];
          });
        });
        this.checkValidation();
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  getAgentName(email) {
    let name = '';
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        name =
          this.totalAgents[k].FirstName + ' ' + this.totalAgents[k].LastName;
        return name;
      }
    }
  }
  getAgentInitial(email) {
    let initial = '';
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        initial =
          this.totalAgents[k].FirstName.charAt(0) +
          this.totalAgents[k].LastName.charAt(0);
        return initial;
      }
    }
  }

  getAgentColor(email) {
    let color = '';
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        color =
          this.totalAgents[k].FirstName.charAt(0) +
          this.totalAgents[k].LastName.charAt(0);

        let randomIndex =
          this.colors[
          this.numberFromText(this.getAgentInitial(email)) %
          this.colors.length
          ];
        color = randomIndex[0]['bg'];
        return color;
      }
    }
  }
  getAgentphoto(email) {
    let imageUrl = '';
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        imageUrl = this.totalAgents[k].ProfilePhoto;
        return imageUrl;
      }
    }
  }

  numberFromText(text) {
    const charCodes = text
      .split('') // => ["A", "A"]
      .map((char) => char.charCodeAt(0)) // => [65, 65]
      .join(''); // => "6565"
    return parseInt(charCodes, 10);
  }

  changeValidation(value, docId) {
    this.uhleadsservice
      .updateValidationStatus({
        StudentdocId: docId,
        // admin: this.admin,
        admin: false,
        validationStatus: value,
        Platform: 'CRM',
        MailId: this.userData.mail,
      })
      .subscribe(
        (res) => {
          this.documentValidation(this.currentLeadId);
          this.sideNavData({ id: this.currentLeadId });
          this.uhleadsservice.showSuccess(
            'Document Status updated!',
            'Success!'
          );
          this.getCardCount(
            {
              admin: this.admin,
              mail: this.userData.mail,
              ProjectId: this.projectId,
              leadType: this.cardLeadType,
              start: this.startDate,
              end: this.endDate,
            },
            this.cardLeadType
          );
          let payload = {
            AgentMail: this.userData.mail,
            GBPAssignedTo: '',
            GUID: '',
            LeadId: 0,
            Platform: 'CRM',
            StudentDocId: docId,
            Title: 'Document Validation',
            description:
              'Document status changed to ' +
              value +
              ' by ' +
              this.getAgentName(this.userData.mail),
            notificationType: 'Validation',
            token: this.userData.token,
          };

          this.NotificationService.sendNotifications(payload).subscribe(
            (res: any) => {
              if (res.status === 200) {
              }
            }
          );
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
  }

  checkValidation() {
    if (this.documentsList2.length == 0) {
      this.validationStatus = false;
      return;
    }
    for (var i = 0; i < this.documentsList2.length; i++) {
      if (
        this.documentsList2[i].AgentValidation == 'revalidate' ||
        this.documentsList2[i].AgentValidation == '-'
      ) {
        this.validationStatus = false;
        return;
      } else if (this.documentsList2[i].AgentValidation == 'validated') {
        this.validationStatus = true;
        //AddNotification
      } else {
        this.validationStatus = false;
      }
    }
    if (this.validationStatus == true) {
      // Send Notification

      let payload = {
        AgentMail: 'ramana@universityhub.com',
        GBPAssignedTo: '',
        GUID: '12356yyu98',
        LeadId: 0,
        Platform: 'CRM',
        StudentDocId: 0,
        Title: 'Documents Validated',
        description: 'All the docuiments have been validated',
        notificationType: 'alldocuments',
        token: this.userData.token,
      };

      let payload2 = {
        AgentMail: 'ramana@universityhub.com',
        GBPAssignedTo: '',
        GUID: '12356yyu98',
        LeadId: 0,
        Platform: 'CRMGBP',
        StudentDocId: 0,
        Title: 'Documents Validated',
        description: 'All the docuiments have been validated',
        notificationType: 'alldocuments',
        token: this.userData.token,
      };

      // this.NotificationService.sendNotifications(payload).subscribe(
      //   (res: any) => {
      //     if (res.status === 200) {
      //       this.uhleadsservice.showSuccess(
      //         "All Documents Validated",
      //         "Validation Complete"
      //       );
      //       this.NotificationService.success("Validation", "Notification sent");
      //     }
      //   }
      // );
      // this.NotificationService.sendNotifications(payload2).subscribe(
      //   (res: any) => {
      //     if (res.status === 200) {
      //       // this.uhleadsservice.showSuccess("All Documents Validated", "Validation Complete");
      //       this.NotificationService.success(
      //         "Validation",
      //         "Notification sent to GBP"
      //       );
      //     }
      //   }
      // );
    }
  }

  postComments(item, HTMLid, sideViewerData?: any) {
    this.documentComments = (<HTMLInputElement>(
      document.getElementById(HTMLid)
    )).value;
    var body = {
      ProjectId: this.projectId,
      GUID: this.currentGuid,
      StudentDocId: item.StudentdocId,
      Text: this.documentComments,
      Admin: this.admin,
      // Admin: false,
      LeadId: this.currentLeadId,
      AgentMail: this.userData.mail,
      Platform: 'CRM',
    };

    if (HTMLid == 'leadComments') {
      body.StudentDocId = null;
    }
    this.uhleadsservice.postDocumentComment(body).subscribe(
      (res) => {
        this.documentValidation(this.currentLeadId);
        this.sideNavData(sideViewerData ? sideViewerData : item);
        this.uhleadsservice.showSuccess('Comment Posted!', 'Success!');
        (<HTMLInputElement>document.getElementById(HTMLid)).value = '';

        let payload = {
          LeadId: 0,
          GBPAssignedTo: '',
          Platform: 'CRM',
          GUID: this.currentGuid,
          StudentDocId: 0,
          notificationType: 'LeadComments',
          description: this.getAgentName(this.userData.mail) + ' has commented',
          AgentMail: this.userData.mail,
          Title: 'Comment',
          token: this.userData.token,
        };

        if (HTMLid == 'leadComments') {
          payload.StudentDocId = 0;
        } else if (
          HTMLid == 'documentComments' ||
          HTMLid == 'callingComments'
        ) {
          payload.StudentDocId = item.StudentdocId;
        }
        try {
          this.NotificationService.sendNotifications(payload).subscribe(
            (res: any) => {
              if (res.status === 200) {
                // this.toastr.showSuccess('Successfully send notification', 'Notification')
                // this.NotificationService.success('success', 'loading component');
              } else if (res.status == 500) {
              }
            },
            (error) => {
              this.handleError.handleError(error);
            }
          );
        } catch (err) { }
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  showmore(itemsCount) {
    if (itemsCount < this.minItems) {
    } else if (this.minItems + 5 < itemsCount) {
      this.minItems = this.minItems + 5;
    } else if (this.minItems < itemsCount) {
      this.minItems = itemsCount;
    }
  }

  callFromDocs(data, item) {
    this.startCallListener2(data, 0);
    this.currentItem = item;
  }

  getAgentNames() {
    this.agentService.getAgentNames().subscribe(
      (data) => {
        this.totalAgents = data;

        this.uhleadsservice.getGBPAgentList().subscribe(
          (res: any) => {
            this.GBPagentList = res;
            for (let i = 0; i < this.GBPagentList.length; i++) {
              this.totalAgents.push({
                FirstName: this.GBPagentList[i].FIRST_NAME,
                LastName: this.GBPagentList[i].LAST_NAME,
                Mailid: this.GBPagentList[i].EMAIL_ADDRESS,
                ProfilePhoto: this.GBPagentList[i].ProfilePhoto,
              });
            }
          },
          (error) => {
            this.handleError.handleError(error);
          }
        );
        // this.totalAgents.push({FirstName: "Test", LastName: "Test", MailId: "test@test.com", ProfilePhote:"test.com"})
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  fileUpload(event, item) {
    let fileList: FileList = event.target.files;
    let file: File = fileList[0];
    if (fileList.length > 0) {
      if (!this.validateFile(file.type)) {
        return;
      }
      let formData: FormData = new FormData();
      formData.append('studentdocid', item.StudentdocId);
      formData.append('document', file, file.name);
      formData.append('Platform', 'CRM');
      formData.append('MailId', this.userData.mail);

      this.uhleadsservice.uploadDocument(formData, item.StudentdocId).subscribe(
        (res) => {
          this.uhleadsservice.showSuccess('File upload successful!', 'Success');
          this.sideNavData({ id: this.currentLeadId });
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
    }
  }

  uploadCustomDocument(e) {
    // let fileList: FileList = event.target.files;
    // this.file = fileList[0];
    // event.target.value = '';
    // if (fileList.length > 0) {
    //   if (!this.validateFile(this.file.type)) {
    //     this.uhleadsservice.showError("Invalid file format!", "Error");
    //     return;
    //   }
    // }

    for (var i = 0; i < e.target.files.length; i++) {
      // this.file.push(e.target.files[i]);
      this.customFileList.push(e.target.files[i]);
    }
    if (this.customFileList.length > 0) {
      this.docUploadError = 'Click on add to start uploading your files';
      console.log(this.customFileList);
      var x = document.getElementsByClassName(
        'docUploadError'
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < x.length; i++) {
        x[i].style.color = 'green';
      }
    }
    e.target.value = '';
  }

  addCustomDocument() {
    let check = true;
    this.customFileList.forEach((element) => {
      if (this.validateFile(element.type) == false) {
        this.docUploadError =
          'One or more files you uploaded is not a valid format. Please upload either doc, pdf, png or JPG/JPEG format.';
        check = false;
        var x = document.getElementsByClassName(
          'docUploadError'
        ) as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < x.length; i++) {
          x[i].style.color = 'red';
        }
      }
    });
    if (check == true) {
      this.docUploadError =
        'Please be patient while the document is uploaded...';
      if (this.customDocName == '') {
        this.docUploadError = 'Document name cannot be empty!';
        return;
      } else {
        let formData: FormData = new FormData();
        formData.append('GUID', this.currentGuid);
        formData.append('docName', this.customDocName);
        formData.append('Platform', 'CRM');
        formData.append('MailId', this.userData.mail);

        Array.from(this.customFileList).forEach((file) =>
          formData.append('document', file, file.name)
        );
        this.docUploadStatus = true;
        this.uhleadsservice.uploadCustomDocument(formData).subscribe(
          (res: any) => {
            let response = res.status;
            this.docUploadStatus = false;
            if (response == 200) {
              this.docUploadError = 'Custom document added';
              this.uhleadsservice.showSuccess(
                'Custom document added!',
                'Success'
              );
            } else if (response == 201) {
              this.docUploadError = 'Uploaded a custom document';
              this.uhleadsservice.showSuccess(
                'Uploaded a custom document!',
                'Success'
              );
            } else if (response == 202) {
              this.docUploadError = 'Document with given name already exists!';
              this.uhleadsservice.showError(
                'Document with given name already exists!',
                'Error'
              );
              return;
            }
            this.sideNavData({ id: this.currentLeadId });
            this.closeButton.nativeElement.click();
            this.file = null;
            this.customDocName = '';
          },
          (err) => {
            this.docUploadStatus = false;
            if (err.error.status == 400) {
              this.docUploadError = `The file ${err.error.file} is corrupted. Please upload valid files .`;
            } else if (err.error.status == 500) {
              this.docUploadError = err.error.message;
            } else {
              this.docUploadError =
                'Files not uploaded. Please check document extensions, upload supported documents.';
            }
            this.uhleadsservice.showError(this.docUploadError, 'Error');
            this.handleError.handleError(err);
          }
        );
      }
    }
  }

  deleteCustomDocument(docId, docName) {
    this.uhleadsservice
      .deleteCustomDocument({
        StudentdocId: docId,
        docName: docName,
        Platform: 'CRM',
        MailId: this.userData.mail,
      })
      .subscribe(
        (res: any) => {
          let response = res.status;
          if (response == 200) {
            this.uhleadsservice.showSuccess(
              'Custom document deleted successfully.',
              'Deleted Successfully!'
            );
          } else {
            this.uhleadsservice.showError('Something went wrong!', 'Error!');
          }
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
    this.sideNavData({ id: this.currentLeadId });
  }

  downLoadDocument(url, name) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('type', 'hidden');
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  //Validate Uploaded file
  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'image/jpeg') {
      return true;
    } else if (ext.toLowerCase() == 'document') {
      return true;
    } else if (ext.toLowerCase() == 'image/png') {
      return true;
    } else if (ext.toLowerCase() == 'application/msword') {
      return true;
    } else if (
      ext.toLowerCase() ==
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      return true;
    } else if (ext.toLowerCase() == 'application/pdf') {
      return true;
    } else {
      this.uhleadsservice.showError('Please upload a valid file!', 'Error!');
      return false;
    }
  }

  openPreview(content, url) {
    // this.docUrl = '';
    this.docUrl = url;
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: 'myCustomModalClass',
      })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }

  contentLoaded() {
    document.getElementById('progressBar').style.display = 'none';
  }

  playAudio(data) {
    // let audio = new Audio();
    if (this.audioStatus == false) {
      this.audio.src = data.RecordingURL;
      this.audio.pause();
      this.audio.currentTime = 0;
      // this.audio.load();
      this.audio.play();
      this.audioStatus = true;
    } else if (this.audioStatus == true) {
      this.audio.pause();
      this.audio.currentTime = 0;
      this.audioStatus = false;
    }
  }
  audioPlay(e) {
    let eAudio = this.domService.getElementsByTagName('audio');
    if (eAudio && eAudio.length > 0) {
      for (var i = 0; i < eAudio.length; i++) {
        if (e.target !== eAudio[i]) {
          eAudio[i].pause();
        }
      }
    }
  }

  pauseAudio(data) {
    // audio.stop();
  }

  callNext() { }

  stopCurrentCall() {
    this.uhleadsservice.startCallsfc.next(null);
    this.uhleadsservice.stopCallsfc.next({
      phoneNumber: this.marketingId.phoneNumber,
    });
    this.callStatus = 'Call Ended';
  }

  stopCallListener() {
    this.uhleadsservice.startCallsfc.next(null);
    this.uhleadsservice.stopCallsfc.next({
      phoneNumber: this.marketingId.phoneNumber,
    });
    this.stopCall = true;
    this.startCall = false;
    if (this.statusValue !== 'pending') {
      this.startIndex += 1;
    }
    clearTimeout(this.clearTime);
  }

  followUpCall(data) {
    if (!this.startCall) {
      this.layoutService.marketingCall.next({
        id: data['id'],
        number: '+' + data['CountryCode'] + data['PhoneNumber'],
        name: data['FirstName'],
        email: data['Email'],
        comments: data['Comments'],
        module: data['Module'],
        city: data['city'],
        nextCaller: null,
        lead: data,
      });
      this.startCall = true;
    }
  }

  openCreateLead(content) {
    //   gtag('event', 'add_new_lead', {
    //     page_path: window.location.href,
    //     page_title:'Leads Dashboard'
    //  })
    this.modalService.open(content, { size: 'md' });
    let country = countryCodes.find((x) => x.callingCodes[0] == 1);
    this.selectCountry(country);
  }
  openEditLead(content, lead) {
    if (lead.DOB == '-') {
      lead.DOB = '';
    } else {
      lead.DOB = moment(lead.DOB).format('YYYY-MM-DD');
    }

    this.editStatus = lead.CallStatus;
    this.DIterms = lead.DIterms;
    this.DIyear = parseInt(lead.DIyear);
    if (this.editStatus == 'deferredintake') {
      this.showdeferredintake = true;
    } else {
      this.showdeferredintake = false;
      this.DIyear = null;
      this.DIterms = null;
    }
    this.leadtoEdit = {
      LeadId: lead.id,
      FirstName: lead.FirstName,
      LastName: lead.LastName,
      CountryCode: lead.CountryCode,
      PhoneNumber: lead.PhoneNumber,
      Email: lead.Email,
      DOB: lead.DOB,
      // DOB: this.startDate,
      EnrollmentYear: lead.EnrollmentYear,
      EnrollmentTerm: lead.EnrollmentTerm,
      Address: lead.Address,
      City: lead.City,
      State: lead.State,
      Zip: lead.Zip,
      Country: lead.Country,
      LeadComment: this.editComment,
      CallStatus: this.editStatus,
      AgentMail: this.userData.mail,
      studentapplicationtype: lead.applicationtype ? lead.applicationtype : '',
    };

    this.modalService.open(content, { size: 'lg' });

    this.uhleadsservice.editLead.markAllAsTouched();
    if (lead.Country != '-') {
      let country = countryCodes.find(
        (x) => x.alpha2Code == lead.Country || x.name == lead.Country
      );
      this.selectCountryEditName(country);
    }
    if (lead.CountryCode != '-') {
      let country1 = countryCodes.find(
        (x) => x.callingCodes[0] == lead.CountryCode
      );
      this.selectCountryEdit(country1);
    }
  }

  replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }
  validateEmailId(value) {
    this.uhleadsservice.emailIdValidator({ email: value }).subscribe(
      (data: any) => {
        console.log(
          '🚀 ~ file: registration.component.ts:143 ~ RegistrationComponent ~ this.registrationService.emailIdValidator ~ data',
          data
        );
      },
      (error) => {
        this.createLeads['email'].markAsTouched();
        this.uhleadsservice.showError('Email Already Exist!', '');
      }
    );
  }
  updateLead() {
    if (this.leadtoEdit.EnrollmentTerm === '-') {
      this.leadtoEdit.EnrollmentTerm = null;
      this.uhleadsservice.editLead.controls['EnrollmentTerm'].setValue(null);
    }
    if (this.leadtoEdit.EnrollmentYear === '-') {
      this.leadtoEdit.EnrollmentYear = null;
      this.uhleadsservice.editLead.controls['EnrollmentYear'].setValue(null);
    }
    this.edited = true;
    if (this.uhleadsservice.editLead.invalid) {
      this.uhleadsservice.editLead.markAllAsTouched();
      return;
    }

    // this.leadtoEdit.CountryCode = this.leadtoEdit.CountryCode.replace("+", "");
    this.leadtoEdit.CountryCode = this.replaceAll(
      this.leadtoEdit.CountryCode,
      '+',
      ''
    );
    this.leadtoEdit.DIterms = this.DIterms;
    this.leadtoEdit.DIyear = this.DIyear;
    if (this.leadtoEdit.CountryCode == '-') {
      this.leadtoEdit.CountryCode = '00';
    }
    if (this.leadtoEdit.PhoneNumber == '-') {
      this.leadtoEdit.PhoneNumber = '0000000000';
    }
    var keys = Object.keys(this.leadtoEdit);
    keys.forEach((a) => {
      if (
        this.leadtoEdit[a] === '-' ||
        this.leadtoEdit[a] === '' ||
        this.leadtoEdit[a] === undefined
      ) {
        this.leadtoEdit[a] = null;
      }
    });

    this.uhleadsservice.updateLead(this.leadtoEdit).subscribe(
      (res: any) => {
        this.uhleadsservice.showSuccess('Student details updated', 'Success!');
        this.edited = false;

        document.getElementById('closeButton3').click();
        // this.getCounts(this.userData, "all", this.startDate, this.endDate, "all");
        this.getTopBarCRM();

        this.getCardCount(
          {
            admin: this.admin,
            mail: this.userData.mail,
            ProjectId: this.projectId,
            leadType: this.cardLeadType,
            start: this.startDate,
            end: this.endDate,
            page: 1,
          },
          this.cardLeadType
        );
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  // Clear file and document name in custom document upload modal
  clearData() {
    this.file = null;
    this.customDocName = '';
    this.customFileList = [];
  }

  selectFiles() {
    const frmData = new FormData();

    for (var i = 0; i < this.selectedFiles.length; i++) {
      frmData.append('fileUpload', this.selectedFiles[i]);
    }
  }

  getFileDetails(e) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.selectedFiles.push(e.target.files[i]);
    }
    if (this.selectedFiles.length > 0) {
      this.docUploadError2 = 'Click on upload to start uploading your files';

      var x = document.getElementsByClassName(
        'docUploadError'
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < x.length; i++) {
        x[i].style.color = 'green';
      }
    }
    e.target.value = '';
  }

  deleteFile(i) {
    this.selectedFiles.splice(i, 1);
  }

  uploadAttachments() {
    let check = true;
    this.selectedFiles.forEach((element) => {
      if (this.validateFile(element.type) == false) {
        this.docUploadError2 =
          'One or more files you uploaded is not a valid format. Please upload either doc, pdf, png or JPG/JPEG format.';
        check = false;
        var x = document.getElementsByClassName(
          'docUploadError'
        ) as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < x.length; i++) {
          x[i].style.color = 'red';
        }
      }
    });
    if (check == true) {
      var x = document.getElementsByClassName(
        'docUploadError'
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < x.length; i++) {
        x[i].style.color = 'green';
      }
      this.docUploadError2 =
        'Compressing your documents. Please wait for the process to finish';
      this.docUploadStatus = true;

      let formData: FormData = new FormData();
      formData.append('studentdocid', this.docUploadID);
      formData.append('docName', this.docName);
      Array.from(this.selectedFiles).forEach((file) =>
        formData.append('document', file, file.name)
      );
      formData.append('Platform', 'CRM');
      formData.append('MailId', this.userData.mail);

      this.uhleadsservice.uploadDocument(formData, this.docUploadID).subscribe(
        (res: any) => {
          // this.uhleadsservice.showSuccess("File upload successful!", "Success");

          if (res.status == 200) {
            this.cancelUpload();
            this.uploadSuccess = 'success';
            this.sideNavData({ id: this.currentLeadId });
            this.uhleadsservice.showSuccess(
              'File upload successful!',
              'Success'
            );
            this.getCardCount(
              {
                admin: this.admin,
                mail: this.userData.mail,
                ProjectId: this.projectId,
                leadType: this.cardLeadType,
                start: this.startDate,
                end: this.endDate,
              },
              this.cardLeadType
            );
            this.docUploadStatus = false;

            // document.getElementById("closeButton4").click();
            try {
              // document.getElementById("closeButton4").click();
            } catch (err) {
              this.uploadSuccess = 'failure';
              if (err.error.status == 400) {
                this.uhleadsservice.showError(
                  `The file ${err.error.file} is corrupted. Please upload valid files .`,
                  ''
                );
              } else if (err.error.status == 500) {
                this.uhleadsservice.showError(err.error.message, '');
              } else {
                this.uhleadsservice.showError(
                  'Files not uploaded. Please check document extensions, upload supported documents.',
                  ''
                );
              }
            }
          }
        },
        (err) => {
          this.handleError.handleError(err);
          this.uploadSuccess = 'failure';
          this.docUploadStatus = false;
          if (err.error.status == 400) {
            this.uhleadsservice.showError(
              `The file ${err.error.file} is corrupted. Please upload valid files .`,
              ''
            );
          } else if (err.error.status == 500) {
            this.uhleadsservice.showError(err.error.message, '');
          } else {
            this.uhleadsservice.showError(
              'Files not uploaded. Please check document extensions, upload supported documents.',
              ''
            );
          }
        }
      );
    }
  }
  cancelUpload() {
    document.getElementById('closeButton4').click();
  }

  clearSelectedFiles(item) {
    this.docUploadID = item.StudentdocId;

    this.getDocNameByDocId(this.docUploadID);
    this.docUploadStatus = false;
    this.selectedFiles = [];
    this.docUploadError2 = 'Select files to upload.';
    this.uploadSuccess = 'none';

    var x = document.getElementsByClassName(
      'docUploadError'
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.color = 'green';
    }
  }

  open() {
    let payload = {
      LeadId: 0,
      GBPAssignedTo: '',
      Platform: 'CRM',
      // GUID: "f083dc22-9ed2-4204-b46c-d4e2958b6de0",
      GUID: '12356yyu98',
      StudentDocId: 0,
      notificationType: 'LeadComments',
      description: 'Test Comment',
      AgentMail: 'ramana@universityhub.com',
      Title: 'Test',
      token: this.userData.token,
    };

    let payload2 = {
      AgentMail: 'ramana@universityhub.com',
      GBPAssignedTo: '',
      GUID: '12356yyu98',
      LeadId: 0,
      Platform: 'CRM',
      StudentDocId: 0,
      Title: 'Comment',
      description: '12356yyu98',
      notificationType: 'LeadComments',
      token: this.userData.token,
    };

    this.NotificationService.sendNotifications(payload).subscribe(
      (res: any) => {
        if (res.status === 200) {
        }
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );

    this.NotificationService.sendNotifications(payload2).subscribe(
      (res: any) => {
        if (res.status === 200) {
        }
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  viewLead(id) {
    this.sideNav.toggle();
    this.sideNavData({ id: id });
  }

  onInputActivity(event: any) {
    if (this.activityfilterString != '') {
      this.minItems = event;
    } else {
      this.minItems = 5;
    }
  }
  getTopBarCRM() {
    var postData = {
      ProjectId: this.projectId,
      start: this.startDate,
      end: this.endDate,
      AgentMail: this.userData.mail,
    };
    this.isTopBarBind = false;
    this.uhleadsservice
      .getTopBarCRM(postData)
      .toPromise()
      .then((resp: any) => {
        this.totalPages = 1;
        if (this.leadType == 'all') {
          this.totalPages = resp.TopBarCount.total;
        } else if (this.leadType == 'assigned') {
          this.totalPages = resp.TopBarCount.assigned;
        } else if (this.leadType == 'assignedtoMe') {
          this.totalPages = resp.TopBarCount.assignedtoMe;
        } else if (this.leadType == 'completedDocs') {
          this.totalPages = resp.TopBarCount.completedDocs;
        } else if (this.leadType == 'documentsSubmitted') {
          this.totalPages = resp.TopBarCount.documentsSubmitted;
        } else if (this.leadType == 'pendingDocs') {
          this.totalPages = resp.TopBarCount.pendingDocs;
        } else if (this.leadType == 'reopen') {
          this.totalPages = resp.TopBarCount.reopen;
        } else if (this.leadType == 'revalidatedDocs') {
          this.totalPages = resp.TopBarCount.revalidatedDocs;
        } else if (this.leadType == 'studentDenied') {
          this.totalPages = resp.TopBarCount.studentDenied;
        } else if (this.leadType == 'unassigned') {
          this.totalPages = resp.TopBarCount.unassigned;
        } else if (this.leadType == 'uncontactable') {
          this.totalPages = resp.TopBarCount.uncontactable;
        } else if (this.leadType == 'validatedDocs') {
          this.totalPages = resp.TopBarCount.validatedDocs;
        } else if (this.leadType == 'pending') {
          this.totalPages = resp.TopBarCount.pending;
        } else if (this.leadType == 'called') {
          this.totalPages = resp.TopBarCount.called;
        } else if (this.leadType == 'callback') {
          this.totalPages = resp.TopBarCount.callback;
        } else if (this.leadType == 'informed') {
          this.totalPages = resp.TopBarCount.informed;
        } else if (this.leadType == 'wrongnumber') {
          this.totalPages = resp.TopBarCount.wrongnumber;
        } else if (this.leadType == 'notanswered') {
          this.totalPages = resp.TopBarCount.notanswered;
        } else if (this.leadType == 'deferredintake') {
          this.totalPages = resp.TopBarCount.deferredintake;
        } else if (this.leadType == 'uncontactable') {
          this.totalPages = resp.TopBarCount.uncontactable;
        } else if (this.leadType == 'graduate') {
          this.totalPages = resp.TopBarCount.graduate;
        } else if (this.leadType == 'graduate_transfer') {
          this.totalPages = resp.TopBarCount.graduate_transfer;
        } else if (this.leadType == 'freshman') {
          this.totalPages = resp.TopBarCount.freshman;
        } else if (this.leadType == 'graduate_visiting_student') {
          this.totalPages = resp.TopBarCount.graduate_visiting_student;
        } else if (this.leadType == 'non_degree') {
          this.totalPages = resp.TopBarCount.non_degree;
        } else if (this.leadType == 'transfer') {
          this.totalPages = resp.TopBarCount.transfer;
        } else if (this.leadType == 'dropdown') {
          this.uhleadsservice
            .getCountByCallStatus({
              status: this.cardLeadType,
              ProjectId: this.projectId,
              start: this.startDate,
              end: this.endDate,
            })
            .subscribe((count: any) => {
              this.totalPages = count.count;
              this.totalPages = Math.ceil(Number(this.totalPages) / 12);

              this.uhleadsservice.totalPages.next(this.totalPages);
            });
        }
        // this.totalPages = ~~(Number(this.totalPages) / 12) == 0 ? ~~(Number(this.totalPages) / 12) + 1 : ~~(Number(this.totalPages) / 12);
        this.totalPages = Math.ceil(Number(this.totalPages) / 12);
        this.uhleadsservice.totalPages.next(this.totalPages);

        this.topBarCounts = resp.TopBarCount;
        this.isTopBarBind = true;
      })
      .catch((err) => {
        this.handleError.handleError(err);
      });
  }

  sortData(arr, value) {
    return arr.sort((a, b) => {
      return a[value] - b[value];
      //return a[value] < b[value] ? -1 : a[value] > b[value] ? 1: 0
    });
  }
  toggleSort(column, order) {
    if (this.sortOrder == 'asc') {
      this.sortOrder = 'des';
    } else {
      this.sortOrder = 'asc';
    }
    this.sortTableData(column, this.sortOrder);
  }

  sortTableData(column, order) {
    if (column == 'createdAt') {
      if (this.currentCard == 'total') {
        column = 'createdAt';
      } else if (this.currentCard == 'validated') {
        column = 'ValidatedDate';
      } else if (this.currentCard == 'uncontactable') {
        column = 'createdAt';
      } else if (this.currentCard == 'completed') {
        column = 'CompletedDate';
      } else if (this.currentCard == 'updated') {
        column = 'createdAt';
      } else if (this.currentCard == 'revalidate') {
        column = 'RevalidateDate';
      } else if (this.currentCard == 'denied') {
        column = 'DeniedDate';
      } else if (this.currentCard == 'assigned') {
        column = 'createdAt';
      }
    }
    this.sortColumn = column;
    this.activityTable = sortJsonArray(
      this.activityTable,
      column,
      this.sortOrder
    );

    // if (this.sortOrder == 'asc'){
    //   this.sortOrder ='des'
    // }else{
    //   this.sortOrder = 'asc';
    // }
  }

  getCardCount(obj, type) {
    this.cardLeadType = type;
    this.leadIds = [];
    this.activityTable = [];
    this.isLoading = true;
    this.noRecords = false;
    this.search = '';
    this.activityData2 = [];
    this.activityData = [];
    this.companyService.projectAdmin(this.companyId).subscribe(
      (projects) => {
        delete obj.ProjectId;
        obj.ProjectId = projects.filter(
          (x) => x.Name == environment.Sfcleads
        )[0].id;
        // obj.ProjectId = data;
        obj.page = this.pageNumber;
        obj.leadType = this.cardLeadType;
        this.uhleadsservice
          .getCardLeadByType(obj)
          .toPromise()
          .then((resp: any) => {
            this.activityData2 = resp;
            this.activityData2.forEach(function (a) {
              Object.keys(a).map((key) => {
                a[key] === null ? (a[key] = '-') : a[key];
              });
            });
            for (let i = 0; i < this.activityData2.length; i++) {
              if (this.activityData2[i].PhoneNumber == '0000000000') {
                this.activityData2[i].PhoneNumber = '-';
                this.activityData2[i].CountryCode = '-';
              }

              this.activityData2[i].AssignedtoName = this.getAgentName(
                this.activityData2[i].Assignedto
              );
              this.activityData2[i].AssignedtoInitial = this.getAgentInitial(
                this.activityData2[i].Assignedto
              );
              this.activityData2[i].AssignedtoColor = this.getAgentColor(
                this.activityData2[i].Assignedto
              );
              this.activityData2[i].AssignedtoImageURL = this.getAgentphoto(
                this.activityData2[i].Assignedto
              );
              if (
                this.activityData2[i].LeadDocStatus == 'CRM-Revalidate' ||
                this.activityData2[i].LeadDocStatus == 'Admin-Revalidate' ||
                this.activityData2[i].LeadDocStatus == 'Admin-revalidate' ||
                this.activityData2[i].LeadDocStatus == 'CRM-revalidate'
              ) {
                this.activityData2[i].LeadDocStatus = 'Revalidate';
              }
            }
            this.activityData = this.activityData2;
            this.sortTableData(this.sortColumn, this.sortOrder);

            this.activityTable = this.activityData;
            this.isLoading = false;
            if (this.activityTable.length == 0) {
              this.noRecords = true;
            } else {
              this.noRecords = false;
            }
          })
          .catch((err) => {
            this.handleError.handleError(err);
          });
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  getCardData(userData: any, leadtype, startDate, endDate) {
    this.leadIds = [];
    this.isLoading = true;
    this.noRecords = false;
    this.search = '';
    this.onClick(null);
    this.activityTable = [];
    let obj = {
      admin: this.admin,
      // admin: true,
      mail: userData.mail,
      ProjectId: this.projectId,
      leadType: leadtype,
      start: this.startDate,
      end: this.endDate,
      page: this.pageNumber,
    };

    this.uhleadsservice
      .getCardLeadByType(obj)
      .toPromise()
      .then((resp: any) => {
        //
        this.activityTable2 = resp;
        this.activityTable2.forEach(function (a) {
          Object.keys(a).map((key) => {
            a[key] === null ? (a[key] = '-') : a[key];
          });
        });
        //

        for (let i = 0; i < this.activityTable2.length; i++) {
          if (this.activityTable2[i].PhoneNumber == '0000000000') {
            this.activityTable2[i].CountryCode = '-';
            this.activityTable2[i].PhoneNumber = '-';
          }
          this.activityTable2[i].AssignedtoName = this.getAgentName(
            this.activityTable2[i].Assignedto
          );

          this.activityTable2[i].AssignedtoInitial = this.getAgentInitial(
            this.activityTable2[i].Assignedto
          );
          this.activityTable2[i].AssignedtoColor = this.getAgentColor(
            this.activityTable2[i].Assignedto
          );
          this.activityTable2[i].AssignedtoImageURL = this.getAgentphoto(
            this.activityTable2[i].Assignedto
          );
          if (
            this.activityTable2[i].LeadDocStatus == 'CRM-Revalidate' ||
            this.activityTable2[i].LeadDocStatus == 'Admin-Revalidate' ||
            this.activityTable2[i].LeadDocStatus == 'Admin-revalidate' ||
            this.activityTable2[i].LeadDocStatus == 'CRM-revalidate'
          ) {
            this.activityTable2[i].LeadDocStatus = 'Revalidate';
          }
        }
        this.activityTable = this.activityTable2;
        this.getAllData(
          this.userData,
          'total',
          new Date(Date.now() - 864e5 * 365),
          new Date(Date.now())
        );
        this.callData = this.activityTable;
        this.isLoading = false;
        if (this.activityTable.length == 0) {
          this.noRecords = true;
        } else {
          this.noRecords = false;
        }
      })
      .catch((err) => {
        this.handleError.handleError(err);
      });
  }
  checkThresHold(content, item, id) {
    this.thresItem = item;
    this.thresId = id;
    if (item.TotalCalls > 12) {
      this.thresModal = this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: 'myCustomModalClass',
      });
    } else {
      this.startCallListener2(this.thresItem, this.thresId);
      document.getElementById('openModalButton').click();
    }
  }
  thresHold() {
    this.thresModal.close();
    this.startCallListener2(this.thresItem, this.thresId);
    document.getElementById('openModalButton').click();
  }
  onDIItermsChange(data) {
    this.DIterms = data;
  }
  onDIYearChange(data) {
    this.DIyear = data;
  }
  getLeadDocStatusName(value) {
    switch (value) {
      case 'notsubmitted':
        return 'Not Submitted';
        break;
      case 'previousintake':
        return 'Previous Intake';
        break;

      default:
        return value;
        break;
    }
  }
  sendSmsWp(item, HTMLid) {
    this.smsText = (<HTMLInputElement>document.getElementById(HTMLid)).value;
    let payload = {
      text: this.smsText,
      from: environment.whatsApp_Sender,
      to: '+' + item.CountryCode.replace('+', '') + item.PhoneNumber,
      AgentMail: this.userData.mail,
    };

    this.uhleadsservice
      .sendWhatsapp(payload, environment.whatsApp_Sender_projectId)
      .subscribe((res: any) => {
        this.uhleadsservice.showInfo('Message successfully sent.', 'Sent');
        let wpObj = {
          phone: '+' + item.CountryCode.replace('+', '') + item.PhoneNumber,
        };
        this.uhleadsservice.getSmsWp(wpObj).subscribe((wpsmsList: any) => {
          if (wpsmsList) {
            this.smsListWp = wpsmsList?.data;
            (<HTMLInputElement>document.getElementById(HTMLid)).value = '';
          }
        });
      });
  }
  selectCountry(country) {
    this.selectedCountryCode = country.callingCodes[0];
    this.uhleadsservice.createLead
      .get('countryCode')
      .patchValue(this.selectedCountryCode);
  }
  selectCountryEdit(country) {
    this.selectedCountryCode = country.callingCodes[0];
    this.uhleadsservice.editLead
      .get('CountryCode')
      .patchValue(this.selectedCountryCode);
    this.leadtoEdit.CountryCode = this.selectedCountryCode.toString();
  }
  selectCountryEditName(country) {
    this.selectedCountryName = country.name;
    this.leadtoEdit.Country = this.selectedCountryName;
    this.uhleadsservice.editLead.get('Country').patchValue(country?.alpha2Code);
  }
  fetchCountries() {
    this.countries = countryCodes;
  }
  onInputC(Event: any) {
    let search = Event.target.value;
    let serachData = [];
    if (search) {
      this.countries = countryCodes.filter((x) => {
        return x.name.toLowerCase().includes(search.toLowerCase());
      });
    } else {
      this.countries = countryCodes;
    }
  }
  // onInputProgram(Event: any) {
  //   let search = Event.target.value;
  //   console.log(search)
  //   if (search) {
  //     this.majors = this.allMajors.filter((x) => {
  //       return x.name.toLowerCase().includes(search.toLowerCase());
  //     });
  //   } else {
  //     this.majors = this.allMajors;
  //   }
  // }
  onInputProgram(Event: any) {
    let search = Event.target.value;
    if (search) {
      if (
        this.createLeads.studentapplicationtype.value == 'freshman' ||
        this.createLeads.studentapplicationtype.value == 'transfer' ||
        this.createLeads.studentapplicationtype.value ==
        'non_degree'
      ) {
        this.listTerms = [];
        this.listTerms = this.terms.filter(
          (x) => x.term === 'Fall' || x.term === 'Spring'
        );
        this.majors = this.allMajors.filter((x) => {
          if (
            x.studentType == 'undergraduate' &&
            (x.name.toLowerCase().includes(search.toLowerCase()) ||
              x.value.toLowerCase().includes(search.toLowerCase()))
          ) {
            // if (
            //   this.createLeads.studentapplicationtype.value == 'freshman' ||
            //   this.createLeads.studentapplicationtype.value ==
            //   'non_degree'
            // )
            // return x.value != 'BS_NUR';
            if (
              this.createLeads.studentapplicationtype.value == 'freshman'
            ) {
              return x.value != 'BS_NUR' && !x.value.includes('ND_')
            } else if(this.createLeads.studentapplicationtype.value == 'transfer') {
              return !x.value.includes('ND_')
            } else if (
              this.createLeads.studentapplicationtype.value ==
              'non_degree') {
              return x.value.includes('ND_');
            }
            else return x;

          }
        });
      } else {
        this.listTerms = this.terms.filter(
          (x) => x.term !== 'Fall' && x.term !== 'Spring'
        );
        this.majors = this.allMajors.filter(
          (x) =>
            x.studentType == 'graduate' &&
            (x.name.toLowerCase().includes(search.toLowerCase()) ||
              x.value.toLowerCase().includes(search.toLowerCase()))
        );
      }
    } else {
      if (
        this.createLeads.studentapplicationtype.value == 'freshman' ||
        this.createLeads.studentapplicationtype.value == 'transfer' ||
        this.createLeads.studentapplicationtype.value ==
        'non_degree'
      ) {
        this.listTerms = [];
        this.listTerms = this.terms.filter(
          (x) => x.term === 'Fall' || x.term === 'Spring'
        );

        this.majors = this.allMajors.filter((x) => {
          if (x.studentType == 'undergraduate') {
            // if (
            //   this.createLeads.studentapplicationtype.value == 'freshman' ||
            //   this.createLeads.studentapplicationtype.value ==
            //   'non_degree'
            // )
            //   return x.value != 'BS_NUR';
            if (
              this.createLeads.studentapplicationtype.value == 'freshman'
            ) {
              return x.value != 'BS_NUR' && !x.value.includes('ND_')
            } else if (this.createLeads.studentapplicationtype.value == 'transfer') {
              return !x.value.includes('ND_')
            } else if (
              this.createLeads.studentapplicationtype.value ==
              'non_degree') {
              return x.value.includes('ND_');
            }
            else return x;
          }
        });
      } else {
        this.listTerms = this.terms.filter(
          (x) => x.term !== 'Fall' && x.term !== 'Spring'
        );
        this.majors = this.allMajors.filter((x) => x.studentType == 'graduate');
      }
    }
  }

  onSelectProgram(programValue: any) {
    this.selectedProgram = programValue.name;
    this.uhleadsservice.createLead.controls['program'].setValue(
      programValue.value
    );
  }
  onSelectTerms(termsValue: any) {
    this.selectedTerm = termsValue.name;
    this.uhleadsservice.createLead.controls['EnrollmentTerm'].setValue(
      termsValue.term
    );
    this.uhleadsservice.createLead.controls['EnrollmentYear'].setValue(
      termsValue.year
    );
  }

  getDocNameByDocId(id) {
    let obj = {
      docId: id,
    };
    this.uhleadsservice.getDocNameByDocId(obj).subscribe(
      (data: any) => {
        if (data.data && data.data.ProgramDoc) {
          this.docName =
            new Date().getTime() +
            Math.floor(100000 + Math.random() * 900000).toString() +
            '_' +
            data.data.ProgramDoc;
        } else {
          this.docName =
            new Date().getTime() + Math.floor(100000 + Math.random() * 900000);
        }
      },
      (err) => { }
    );
  }
  onstudentTypeChange(event: any) {
    this.uhleadsservice.editLead.controls['studentapplicationtype'].setValue(
      event
    );
    this.leadtoEdit.studentapplicationtype = event;
  }

  onApplicationType(item) {
    this.selectedTerm = '';
    this.selectedProgram = '';
    if (
      item === 'freshman' ||
      item === 'transfer' ||
      item == 'non_degree'
    ) {
      this.listTerms = [];

      this.listTerms = this.terms.filter(
        (x) => x.term === 'Fall' || x.term === 'Spring'
      );
      this.majors = this.allMajors.filter((x) => {
        if (x.studentType == 'undergraduate') {
          // if (item == 'freshman' || item == 'non_degree')
          //   return x.value != 'BS_NUR';

          if (
            item == 'freshman'
          ) {
            return x.value != 'BS_NUR' && !x.value.includes('ND_')
          } else if (item == 'transfer') {
            return !x.value.includes('ND_')
          } else if (
            item ==
            'non_degree') {
            return x.value.includes('ND_');
          }

          else return x;
        }
      });
    } else {
      this.listTerms = this.terms.filter(
        (x) => x.term !== 'Fall' && x.term !== 'Spring'
      );
      this.majors = this.allMajors.filter((x) => x.studentType == 'graduate');
    }
  }
}
